import React from 'react'
import { graphql } from 'gatsby'
import { bindAll } from 'lodash'

import { Fade, Layout, Preview, Previews, HoverGroup, HoverItem, Split } from '../components'
import { isMobile, pickQuality } from "../../utils/pick-quality"

class Directors extends React.Component {

  title = 'Directors'

  constructor(props) {
    super(props)

    this.bg = React.createRef()

    bindAll(this, ['onMouseLeaveDirectors', 'onCurrentHover', 'onClickDirector'])

    this.state = {
      current: false
    }

    this.leaving = false

    this.previews = []

    this.diff = (nextState, state) => (key) => {
      return (nextState[key] !== state[key])
    }
  }

  onMouseLeaveDirectors() {
    if (this.leaving) return true
    this.setState({current: false})
    Object.values(this.previews).forEach(preview => {
      preview.pause()
    })
    this.bg.current.play()
  }

  onCurrentHover(key) {
    this.previews.forEach(preview => preview.pause())
    this.previews[key].play()
    this.setState({current: key})
    this.bg.current.pause()
  }

  onClickDirector() {
    this.leaving = true
  }

  setPreviewRef = (slug) => (ref) => {
    this.previews[slug] = ref
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false
    const diff = this.diff(nextState, this.state)
    if (diff('current') && !isMobile()) {
      shouldUpdate = true
    }
    return shouldUpdate
  }

  render() {
    return (
      <Layout page={this}>
        <div className="directors">
          <div className="directors__bg">
            <Previews>
              <Preview uid="bg" className="h-full w-full" cover={`${this.props.data.page.cover.url}?auto=format&fit=min&w=1200`} src={pickQuality(this.props.data.page)} autoPlay ref={this.bg}></Preview>
            </Previews>
          </div>

          <div className="directors__slider">
            {
              this.props.data.directors.edges.map(({node: director}) => (
                <div key={director.slug} className={this.state.current === director.slug ? 'directors__slide is-active' : 'directors__slide' }>
                  <Previews>
                    <Preview className="h-full w-full" cover={`${director.cover.url}?auto=format&fit=min&w=1200`} src={pickQuality(director)} noPause ref={this.setPreviewRef(director.slug)}></Preview>
                  </Previews>
                </div>
              ))
            }
          </div>

          <div className="directors__overlay">
            <div className="wrapper" onMouseLeave={this.onMouseLeaveDirectors}>
              <HoverGroup onCurrent={this.onCurrentHover}>
                <ul>
                  {
                    this.props.data.directors.edges.map(({node: director}) => (
                      <li key={director.slug}>
                        <HoverItem id={director.slug}>
                          <Fade to={`/directors/${director.slug}/`} className="block p-6 refresh" onClick={this.onClickDirector}>
                            <span className="font-head text-white tracking-wide leading-normal text-28 xs:text-36 md:text-38 split whitespace-no-wrap"><Split>{director.title}</Split></span>
                          </Fade>
                        </HoverItem>
                      </li>
                    ))
                  }
                </ul>
              </HoverGroup>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Directors

export const query = graphql`
  query DirectorsQuery {
    page: datoCmsDirectorspage {
      mp4Url
      mp4UrlSd
      cover {
        url
      }
    }
    directors: allDatoCmsDirector(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
          mp4Url
          mp4UrlSd
          cover {
            url
          }
        }
      }
    }
  }
`